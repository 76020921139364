import React, { Fragment } from "react"
import FormInput from "../Elements/Form/FormInput"
import FormDate from "../Elements/Form/FormDate"

const PersonalDetails = ({ values = {}, onChange }) => (
  <Fragment>
    <FormInput
      name="firstName"
      label="First Name"
      placeholder="Juan"
      maxLength={50}
      isRequired
    />
    <FormInput
      name="lastName"
      label="Last Name"
      placeholder="Dela Cruz"
      maxLength={50}
      isRequired
    />
    <FormInput
      name="mobileNumber"
      label="Mobile Number"
      placeholder="09123456789"
      isRequired
      maxLength={11}
    />
    <FormInput
      name="emailAddress"
      label="Work Email"
      placeholder="jdelacruz@viatris.com"
      isRequired
    />
    <FormInput
      name="personalEmail"
      label="Personal Email"
      placeholder="jdelacruz@mail.com"
      isRequired
    />
    <FormInput
      name="designation"
      label="Designation"
      placeholder=""
      isRequired
    />
    <FormDate
      name="birthday"
      label="Birthday"
      isRequired
      values={values.birthday}
      onChange={onChange}
    />
  </Fragment>
)

export default PersonalDetails
