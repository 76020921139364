import React, { useContext, useState, useEffect } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"
import PropTypes from "prop-types"

import Section from "../Elements/Section"
import WorkAddress from "../PersonalDetails/WorkAddress"
import Address from "../PersonalDetails/Address"
import AddressTypeChoices from "../PersonalDetails/Address/AddressTypeChoices.js"

import FormTextArea from "../Elements/Form/FormTextarea"
import FormInput from "../Elements/Form/FormInput"
import Message from "../Elements/Message"

import PersonalDetails from "../PersonalDetails/PersonalDetails"

import ActionButtons from "../Elements/ActionButtons"

import { deliveryDetailsValidationSchema } from "./utils/formData"
import { AppContext } from "../../context/AppContext"
import { getContextFromSession } from "../../context/services/context"
import { isObjectEmpty } from "../../services/general"

/**
 * @param {string} backRoute string
 * @param {string} nextRoute string
 */

const DeliveryDetailsForm = ({ backRoute, nextRoute }) => {
  const { dispatch, state } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  let formValues = getContextFromSession()
  let employeeFirstName =
    formValues?.epharmacy?.beneficiaryType?.value === "self"
      ? formValues?.epharmacy?.beneficiaryFirstName
      : ""
  let employeeLastName =
    formValues?.epharmacy?.beneficiaryType?.value === "self"
      ? formValues?.epharmacy?.beneficiaryLastName
      : ""
  if (isObjectEmpty(formValues)) formValues = state

  const addressFieldNames = {
    recipientMobileNumber: `recipientMobileNumber`,
    addressType: `addressType`,
    streetAddress: `deliveryAddress.streetAddress`,
    city: `deliveryAddress.city`,
    province: `deliveryAddress.province`,
    barangay: `deliveryAddress.barangay`,
  }

  const deliveryCaveat = `
  Given the varying quarantine restrictions nationwide, our logistics partners may encounter delays or challenges delivering to certain areas. We will reach out to you with alternative options if our partners encounter any issues with your order.
  `

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [])

  const handleOnSubmit = async (values) => {
    setIsLoading(true)
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: { ...state.epharmacy, ...values },
      },
    })
    setIsLoading(false)
    navigate(nextRoute)
  }

  return (
    <Formik
      initialValues={{
        ...formValues.epharmacy,
        ...formValues.employeeId,
        firstName: employeeFirstName,
        lastName: employeeLastName,
      }}
      onSubmit={handleOnSubmit}
      validationSchema={deliveryDetailsValidationSchema}
    >
      {({ values, setFieldValue, handleChange }) => (
        <Form>
          <Section title="Employee Details">
            <PersonalDetails
              values={values}
              onChange={(event) => handleChange(event)}
            />
          </Section>
          <Section title="Delivery Address">
            <Message color="warning">{deliveryCaveat}</Message>
            <FormInput
              name="recipientMobileNumber"
              label="Recipient's Mobile Number"
              placeholder="09171234567"
              maxLength={11}
              isRequired
            />
            <AddressTypeChoices
              addressTypeName="addressType"
              values={values}
              setFieldValue={setFieldValue}
            />
            {values.addressType === "Home" && (
              <Address
                fieldNames={addressFieldNames}
                values={values}
                isRequired
                isNationwide={true}
                setFieldValue={setFieldValue}
              />
            )}
            {values.addressType === "Office" && (
              <WorkAddress
                values={values}
                isRequired
                setFieldValue={setFieldValue}
              />
            )}
            <FormTextArea
              name="deliveryAddress.notes"
              label="Delivery Notes"
              placeholder="Green gate"
              maxLength={255}
            />
          </Section>
          <ActionButtons
            submit={{ label: "Next", loading: isLoading }}
            back={{ label: "Back", link: backRoute }}
          />
        </Form>
      )}
    </Formik>
  )
}

DeliveryDetailsForm.propTypes = {
  backRoute: PropTypes.string,
  nextRoute: PropTypes.string,
}

export default DeliveryDetailsForm
