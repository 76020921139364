import * as Yup from "yup"
import employeeIDlist from "./employeeIDList.json"

const employeeIDList = employeeIDlist

const ageRestriction = new Date(Date.now()).getFullYear() - 18

export const beneficiaryValidationSchema = Yup.object().shape({
  isOthers: Yup.boolean(),
  beneficiaryFirstName: Yup.string().required("This field is required"),
  beneficiaryLastName: Yup.string().required("This field is required"),
  beneficiaryType: Yup.object().shape({
    value: Yup.string().required("This field is required"),
  }),
  beneficiaryOthers: Yup.string().when("beneficiaryType", {
    is: (beneficiaryType) => beneficiaryType.value === "others",
    then: Yup.string().required("This field is required"),
  }),
})

export const deliveryDetailsValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),
  mobileNumber: Yup.string()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    )
    .required("This field is required"),
  emailAddress: Yup.string()
    .email("Please input a valid email")
    .required("This field is required"),
  personalEmail: Yup.string()
    .email("Please input a valid email")
    .required("This field is required"),
  designation: Yup.string().required("This field is required"),

  birthday: Yup.object({
    month: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
    date: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
    year: Yup.number()
      .min(1940, "Please input a valid year")
      .max(ageRestriction, "You must be at least 18 years old")
      .required("This field is required"),
  }),
  addressType: Yup.string().required("This field is required"),
  recipientMobileNumber: Yup.string()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    )
    .required("This field is required"),
  deliveryAddress: Yup.object().shape({
    streetAddress: Yup.string().required("This field is required"),
    city: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
    barangay: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
    province: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
  }),
})

export const deliveryDetailsInitialValues = {
  firstName: "",
  lastName: "",
  employeeNumber: "",
  mobileNumber: "",
  emailAddress: "",
  deliveryAddress: {
    streetAddress: "",
    city: "",
    province: "",
    notes: "",
  },
}

export const mechanicsValidationSchema = Yup.object().shape({
  agreeToMechanics: Yup.array().min(1, "This field is required"),
  employeeIDNumber: Yup.string()
    .oneOf(
      employeeIDList,
      "Your employee ID is not registered. Please contact HR for more info."
    )
    .required("This field is required"),
  employeeId: Yup.object().shape({
    front: Yup.object().shape({
      path: Yup.string().required("Please upload the necessary file"),
    }),
    back: Yup.object().shape({
      path: Yup.string().required("Please upload the necessary file"),
    }),
  }),
})
